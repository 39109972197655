/* eslint-disable @next/next/no-img-element */
import React from 'react'
import classnames from 'classnames/bind'
import { useWindowSize } from 'react-use'

import Layout from 'components/shared/Layout'
import SingleAnimation from 'components/slices/SingleAnimation'
import LabelTitleText from 'components/shared/LabelTitleText'
import ClientLogo from 'components/shared/ClientLogo'
import css from './styles.module.scss'
import Image from 'next/image'

const cx = classnames.bind(css)

const NewHero = ({
  className,
  intro,
  animation,
  brands,
  forwardRef,
  children,
}) => {
  const { width } = useWindowSize()

  return (
    <section ref={forwardRef} className={cx(css.Hero, className)}>
      <div className={css.background}>
        <div className={css.imgWrap}>
          <Image
            src="/assets/use-case/hero-top-left-image.svg"
            alt="shape"
            className={css.topLeftImage}
            layout="fill"
            objectFit="cover"
            loading="lazy"
          />
        </div>
        <div className={css.imgWrap}>
          <Image
            src="/assets/use-case/hero-top-right-image.svg"
            alt="shape"
            className={css.topRightImage}
            layout="fill"
            objectFit="cover"
            loading="lazy"
          />
        </div>
      </div>

      <Layout type="wrapper" className={css.heroWrapper}>
        <Layout type="innerWrapper">
          {intro && (
            <LabelTitleText
              titleProps={{
                theme: 'white',
                size: 'extra-large',
                tag: 'h1',
              }}
              textProps={{
                theme: 'white',
                className: css.text,
                tag: 'h2',
              }}
              textWithLinkProps={{
                theme: 'white',
              }}
              center={width > 992}
              className={{
                textAlign: 'left',
              }}
              newsWithLinkCustomTextColor="grey-400"
              {...intro}
            />
          )}
          {children}
        </Layout>
        <div className={css.animations}>
          {animation &&
            animation.map((singleAnimation, index) => {
              return (
                <SingleAnimation
                  key={index}
                  className={index ? '' : css.singleAnimation}
                  layout={css.border}
                  {...singleAnimation}
                />
              )
            })}
        </div>
      </Layout>
      <div className={css.clientsLogos}>
        {brands &&
          brands.brands.map((brand, index) => {
            return <ClientLogo key={index} {...brand} />
          })}
      </div>
    </section>
  )
}

NewHero.defaultProps = {
  withTopBackgroundImage: true,
}

export default NewHero
