import React from 'react'

import Layout from 'components/shared/Layout'
import Image from 'components/shared/Image'

import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const SingleAnimation = ({
  className,
  desktopAnimation,
  mobileAnimation,
  layout,
}) => {
  return (
    <Layout className={cx(css.SingleAnimation, layout, className)} type="maxWidth">
      {desktopAnimation && (
        <div className={mobileAnimation ? css.hideOnMobile : ''}>
          <Image {...desktopAnimation} />
        </div>
      )}
      {mobileAnimation && (
        <div className={desktopAnimation ? css.hideOnDesktop : ''}>
          <Image {...mobileAnimation} />
        </div>
      )}
    </Layout>
  )
}

export default SingleAnimation
